import '/style.css';
import gsap from "gsap";

import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function scrollIn() {
    let getScrollInElements = document.querySelectorAll('.slide-in');
    const scrollInElements = gsap.utils.toArray(getScrollInElements);

    scrollInElements.forEach((el) => {
        gsap.from(el, {
            scrollTrigger: el,
            yPercent: 100,
            opacity: 0,
            duration: 1,
            marker: true
        })
    });
}

function footerAnimation() {
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: "footer"
        }
    });

    tl.from('#footer-heading', {
        opacity: 0,
        xPercent: -100,
        duration: 1.8,
        ease: 'power4.inOut'
    });

    tl.from('.footer-links', {
        opacity: 0,
        duration: 1.8,
        delay: -1,
        ease: 'power4.inOut'
    });
}

function hamburger(){
    const hamburger = document.querySelector('.hamburger');
    const navUl = document.querySelector('.mobile-nav');
    
    hamburger.addEventListener('click', (e) => {
            navUl.classList.toggle("visible")
            hamburger.classList.toggle("times");
    });
}

scrollIn();
footerAnimation();
hamburger();